import "./categorypage.scss";
import "./productpage.scss";
import * as dompack from 'dompack';
import * as domevents from "dompack/src/events";
import * as merge from 'dompack/extra/merge';

import $ from "jquery";


// increase/ decrease input amount productpage
dompack.register("#decrease", amountnode => amountnode.addEventListener("click", evt =>
{
  dompack.stop(evt);
  decreaseValue();
}));

dompack.register("#increase", amountnode => amountnode.addEventListener("click", evt =>
{
  dompack.stop(evt);
  increaseValue();
}));

function increaseValue()
{
  let amountnode = dompack.qS('#amount');
  let value = parseInt(dompack.qS('#amount').value, 10);

  value = isNaN(value) ? 0 : value;
  value++;

  dompack.qS('#amount').value = value;
  domevents.fireHTMLEvent(amountnode, 'input');
}

function decreaseValue()
{
  let amountnode = dompack.qS('#amount');
  let value = parseInt(dompack.qS('#amount').value, 10);

  value = isNaN(value) ? 0 : value;
  value < 1 ? value = 1 : '';
  value--;

  dompack.qS('#amount').value = value;
  domevents.fireHTMLEvent(amountnode, 'input');
}

$( document ).ready(function()
{

    //active state tab productpage
    $( '.product__info ul.nav a' ).on( 'click', function ()
    {
      $( '.product__info ul.nav' ).find( 'li.active' ).removeClass( 'active' );
      $( this ).parent( 'li' ).addClass( 'active' );
    });

    // init magnific popup in productpage
    var form = document.getElementById("product__images__preview__container");
    var have_images = document.querySelectorAll('.product__images__grid, .product__images__preview').length !== 0;

    if (typeof (form) != 'undefined' && form !== null && have_images)
    {
        $('.product__images__grid, .product__images__preview').magnificPopup(
        {
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery:
            {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image:
            {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: item => item.el.find('img').attr('alt')
            }
        });
    }

    $('#product__images__preview__container').slick(
    {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: true,
      fade: true,
      asNavFor: '.product__images__grid'
    });

    $('.product__images__grid').slick(
    {
      slidesToShow: 4,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      asNavFor: '#product__images__preview__container',
      dots: false,
      centerMode: false,
      focusOnSelect: true,
      responsive: [
          {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
          {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3
          }
        }
      ]
    });

    // stickytabs productpage
    $('#product__tabs .nav-tabs').stickyTabs();

});

merge.registerUpdater("productPagePreviewImage", (node, data) =>
{
  node.dataset.initialidx = data.idx;
  if (node.classList.contains("slick-initialized"))
    $(node).slick("slickGoTo", data.idx);
});

let target = dompack.qS('#btn-filter');


if (typeof (target) != 'undefined' && target !== null)
{
  target.onclick = function (e)
  {
    let elem = dompack.qS('#productsgrid_anchor');
    elem.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
    e.preventDefault();
  }
}
