import $ from "jquery";
import {Tab, Tooltip, Popover, Button, Dropdown, Util} from 'bootstrap/js/src';
import * as popperjs from 'popper.js/dist/esm/popper.min.js';
import * as dompack from 'dompack';
import * as magnificpopup from 'magnific-popup';
import * as slick from 'slick-carousel/slick/slick.min.js';
import * as sidebarjs from 'sidebarjs';

import 'lazysizes';
import './stickytabs.js';
import './jquery.ddslick.js';

function initOptionDDSlick(select, reinit)
{
  /* what this should do:

    reinit = false: initial init
    reinit = true: re-init

    missing ddslick stuff:
    - hidden options should be hidden in ddslick too (unless currently selected)
    - ddslick destroy doesn't seem to work
  */

  if (reinit)
  {
    // ddslick active?
    const ddinput = select.previousSibling && dompack.closest(select.previousSibling, ".dd-container");
    if (ddinput)
      $(ddinput).ddslick("destroy");
  }

  // Don't trigger for hidden options
  if (dompack.closest(select, ".webshop-product__option--hidden"))
    return;

  try
  {
    $(select).ddslick({
        width: '100%',
        onSelected : function(data)
        {
          dompack.fireModifiedEvents(select);
        }
    });
  }
  catch (e)
  {
    console.log(`error initializing ddslick`, e);
  }

  dompack.registerMissed(select.parentNode);

}
$( document ).ready(function() {

    // Enable popovers everywhere
    $('[data-toggle="popover"]').popover({
        html: true,
        trigger: 'toggle',
        content: function() {
          // get the url for the full size img
          var url = $(this).data('image');
          return '<img src="' + url + '">'
        }
      });

    // sidemenu
    new sidebarjs.SidebarElement({
        component: document.querySelector('[sidebarjs="leftSidebar"]'),
        nativeSwipeOpen: false,
        nativeSwipe: false,
        onOpen: function() 
        {
          document.documentElement.classList.add("sidebarjs--open")
        },
        onClose: function() 
        {
          document.documentElement.classList.remove("sidebarjs--open")
        },
    });

    // filter sidemenu
    var filterpanel = document.querySelector('[sidebarjs="filterSideNav"]');

    if (typeof (filterpanel) != 'undefined' && filterpanel !== null)
    {

      var filterSidebarjs = new sidebarjs.SidebarElement({
          component: document.querySelector('[sidebarjs="filterSideNav"]'),
          nativeSwipeOpen: false,
          nativeSwipe: false,
          onOpen: function() 
          {
            document.documentElement.classList.add("sidebarjs--open")
          },
          onClose: function() 
          {
            document.documentElement.classList.remove("sidebarjs--open")
          },
      });

    }

    // toggle ul in sidemenu
    $(".has-submenu > .submenu-toggle").click(function()
    {
      $(this).nextUntil('.has-submenu').toggleClass('d-block');
      $(this).toggleClass('collapse--open');
      $(this).parent().parent().find('.submenu').hide();
      $(this).parent().parent().removeClass('collapse--open');
    });


    // cycle usps
    var allBoxes = $(".usp_cycle").children("div");
    transitionBox(null, allBoxes.first());

    function transitionBox(from, to) {
        function next() {
            var nextTo;
            if (to.is(":last-child")) {
                nextTo = to.closest(".usp_cycle").children("div").first();
            } else {
                nextTo = to.next();
            }
            to.fadeIn(500, function () {
                setTimeout(function () {
                    transitionBox(to, nextTo);
                }, 5000);
            });
        }

        if (from) {
            from.fadeOut(500, next);
        } else {
            next();
        }
    }

    for (const elt of document.querySelectorAll("#fakeproductoption"))
    elt.addEventListener("change", (evt) =>
    {
      console.log(`change`, evt);
      if (event.target.selectedOptions[0].dataset.redirect)
        window.location.href = event.target.selectedOptions[0].dataset.redirect;
    });

    // ddslick selectmenu product options
    $('.webshop-product__optionselect').each(function()
    {
      let select = this;

      initOptionDDSlick(select);
      select.addEventListener("webshop:optionschanged", evt =>
      {
        initOptionDDSlick(select, true);
      });

    });

});

function enableDDOptionPreview(ddoption)
{
  if (!ddoption.classList.contains("image-preview"))
  {
    const imgprev = $(ddoption);
    if (imgprev.find('img').length > 0)
    {
      imgprev.addClass('image-preview');
      imgprev.attr('rel', $(imgprev.find('img')[0]).attr('src'));

      initImagePreviewForNode(ddoption);
    }
  }
}

function initImagePreviewForNode(node)
{
    var xOffset = 10;
    var yOffset = 30;

    $(node).hover(function(e)
    {
        this.t = this.title;
        this.title = '';
        var c = (this.t !== '') ? '<br>' + this.t : '';
        $('body').append('<div id="image-preview"><img src="'+ this.rel + '" alt="Image preview">' + c + '</div>');
        $('#image-preview')
            .css('top',(e.pageY - xOffset) + 'px')
            .css('left',(e.pageX + yOffset) + 'px')
            .fadeIn('fast');
    },
    function()
    {
        this.title = this.t;
        $('#image-preview').remove();
    });

    $(node).mousemove(function(e)
    {
        $('#image-preview')
            .css('top', (e.pageY - xOffset) + 'px')
            .css('left', (e.pageX + yOffset) + 'px');
    });
}

dompack.register("a.dd-option", enableDDOptionPreview);
