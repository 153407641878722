// Required libraries (all shops)
import '@mod-wrd/js/auth'; //Login and authentication
import '@mod-publisher/js/richcontent/all'; //RTD support
import * as defaultshop from '@mod-webshop/js/composer/defaultshop'; //A shop
import Product from '@mod-webshop/js/shopservice/product';
import * as dialogapi from 'dompack/api/dialog';
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';

// HP Styling/design. This is removable
import 'magnific-popup/dist/magnific-popup.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './shared/custom';
import './web/fonts/fontawesome.css';

import * as dialog from 'dompack/components/dialog';
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';

import './ecoyogishop.scss';
import './rtd';
import './header';
import './pages';
import './footer';
import './widgets';
import './shared/forms';
import './components/select/';

import '@mod-forshops/shopextensions/cookiebar/frontend/cookiebar.es';

function refreshCartStatus(webshop)
{
  dompack.qS('#hp__numincart').textContent = webshop.getCart().numarticles;
}

addEventListener('webshop:cartupdated', function(evt)
{
  refreshCartStatus(evt.detail.webshop);
});


function onProductAdded(evt)
{
  if( !evt.detail.productnode )
    return;//Current page is not an productpage

  if(!evt.defaultPrevented)
  {
    evt.preventDefault();
    if(!dompack.debugflags.noredirect)
      window.setTimeout(() => evt.detail.webshop.gotoCheckout(), 100); //give GTM time to pick up the product add  (TODO fix this in the main code. work with GTA to get supporting browsers into BEACON mode)
  }
}
addEventListener('webshop:productadded', onProductAdded);

//the dialog implementation can differ, but -some- form of dialog callback needs to be registered using dialogapi.setupDialogs
dialogapi.setupDialogs(options => dialog.createDialog('mydialog', options));

function shopReady(webshop)
{
  dompack.register('.productform', node =>
  {
    new Product(webshop, node);
  });

  refreshCartStatus(webshop);
  dompack.dispatchCustomEvent(window, "ecoyogi:shopready", { bubbles:false, cancelable:false, detail: { webshop } });
}

function onNewCartDom(dom)
{
  dompack.qSA(dom,".webshop-listcart__delete").forEach(node => node.appendChild(<span class="fa fa-trash-o" style="font-size:18px;"></span>));
}

//configures the shop and registers handlers
defaultshop.setup(
    { onReady: shopReady
    , onNewCartDom
    });

openLinksInNewWindow( { extensions : ["pdf"] });

dompack.register('.hp-checkout__addtrigger', node => node.addEventListener('click', evt =>
{
  dompack.stop(evt);
  dompack.qSA('.hp-checkout__addcode').forEach(node => node.style.height = node.scrollHeight + 'px');
}));

function mobileProductOptionsPlaceholder()
{
  //on mobile, move product options block to placeholder
  if(!window.matchMedia("(max-width:768px)").matches)
    return;

  let insertionpoint = dompack.qS('.mobile_product__options_placeholder');
  if(!insertionpoint)
    return;

  let moveto = dompack.qS('.product__options');
  if(moveto.length == 0)
    return;
    console.log(moveto);

  insertionpoint.appendChild(moveto);

}

dompack.onDomReady(mobileProductOptionsPlaceholder);

function mobileProductFilterPlaceholder()
{
  //on mobile, move product filter block to placeholder
  if(!document.documentElement.classList.contains("has--filterconfig"))
    return;

  window.addEventListener("orientationchange", function() {
          location.reload(true);
  }, false);

  if(!window.matchMedia("(max-width:992px)").matches)
    return;

  let insertionpoint = dompack.qS('.mobileproductfilter_placeholder');
  if(!insertionpoint)
    return;

  let moveto = dompack.qS('.widget-productfilter');
  if(!moveto)
    return;
    console.log(moveto);

  insertionpoint.appendChild(moveto);

}

dompack.onDomReady(mobileProductFilterPlaceholder);


function fixupMobileOrderTop()
{
  //on mobile, move any item marked as mobile-order-top up
  if(!window.matchMedia("max-width:768px"))
    return;

  let insertionpoint = dompack.qS('.hp__mobileitemsplaceholder');
  if(!insertionpoint)
    return;

  let moveup = dompack.qSA('main > .mobile-order-top');
  if(moveup.length == 0)
    return;

  moveup.forEach(tomoveup =>
  {
    if(tomoveup.previousSibling && tomoveup.previousSibling.nodeName.match(/^H[123456]$/)) //allow the previous heading to move along with us!
      insertionpoint.parentNode.insertBefore(tomoveup.previousSibling, insertionpoint);
    insertionpoint.parentNode.insertBefore(tomoveup, insertionpoint);
  })

}

dompack.onDomReady(fixupMobileOrderTop);

function createFacebookSharerUrl()
{
  let current_url  = window.location.origin + window.location.pathname;
  let insertionpoint = dompack.qS('.facebooksharer_placeholder');
  if(!insertionpoint)
    return;
  let facebookSharerUrl = <a href={"https://www.facebook.com/sharer/sharer.php?u=" + current_url} title="Deel via Facebook"><i class="fa fa-facebook"></i></a>;
  insertionpoint.appendChild(facebookSharerUrl);
}

dompack.onDomReady(createFacebookSharerUrl);

function createPinterestSharerUrl()
{
  let current_url  = window.location.origin + window.location.pathname;
  let insertionpoint = dompack.qS('.pinterestsharer_placeholder');
  if(!insertionpoint)
    return;
  let pinterestSharerUrl = <a href={"http://pinterest.com/pin/create/button/?url=" + current_url} title="Deel via Pinterest"><i class="fa fa-pinterest"></i></a>;
  insertionpoint.appendChild(pinterestSharerUrl);
}

dompack.onDomReady(createPinterestSharerUrl);

//verlanglijstje - verwijder blokje bij klikken
dompack.register("html.ecoyogi--verlanglijstje .ecoyogi__removefromlist", node => node.addEventListener("click", event =>
{
  let holder = dompack.closest(event.target, '.productitem__holder');
  holder.addEventListener("transitionend", () =>
    {
      holder.parentNode.removeChild(holder);
      if(dompack.qSA(".productitem__holder").length == 0) //verlanglijstje leeg!
        location.reload(); //will give feedback about empty list
    });
  holder.classList.add("productitem__holder--fadeout");
}));

if (document.cookie.split(';').filter((item) => item.trim().startsWith('wh-webshop-favlist-wishlist=')).length)
{
  document.getElementById("wishlist-item").classList.remove("d-none");
}

function dumpDataLayer() {
  function getClientIds() {
    const clientids = [];
    if (!window.ga)
      return clientids;

    try {
      for (const tracker of window.ga.getAll()) {
        const trackingId = tracker.get('trackingId');
        const clientId = tracker.get('clientId');
        if (!clientids.find(client => client.type == 'googleanalytics' && client.account == trackingId))
          clientids.push({ type: 'googleanalytics', account: trackingId, clientid: clientId });
      }
      return clientids;
    } catch (e) {
      console.log(e);
      return clientids;
    }
  }

  fetch("/.ecoyogi/logtrace/", { method: "POST", body: JSON.stringify({ datalayer: Array.from([...window.dataLayer]), loc: location.href, clientids: getClientIds() })});
}

dompack.onDomReady(() => setTimeout(dumpDataLayer,1000));
