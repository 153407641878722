import "./widgets.css";
import * as dompack from 'dompack';
import * as finmath from "@mod-system/js/util/finmath";
import * as whintegration from "@mod-system/js/wh/integration";
import cSlider from "../components/slider";
import $ from 'jquery';

let __scriptloaded = false;

class ProductFilter
{
  constructor(node)
  {
    this.node = node;

    let applyFilter = () => this._applyFilter();

    this.grid = document.querySelector(".productsgrid[data-filterconfig]");
    this.filterconfig = null;
    if (this.grid)
      this.filterconfig = JSON.parse(this.grid.dataset.filterconfig);
console.log(this.filterconfig);
    if (this.filterconfig && this.filterconfig.filters && this.filterconfig.filters.length)
    {
      document.documentElement.classList.add("has--filterconfig")

      node.appendChild(
          <div>
            <span class="aside__title">Filter</span>

            { this.activefilterholder = <div class="productfilter__activefilterholder"></div> }
            <div class="productfilter_mobilecontainer">
            <div class="productfilter productfilter-price" data-type="price">
              <a class="productfilter-title" data-toggle="collapse" href="#productfilter-p0" aria-expanded="false" aria-controls="productfilter-p0">Prijs</a>
              <div id="productfilter-p0" class="filtertype--price collapse show">
                <div class="wh-slider-holder">
                  <div class="wh-slider">
                    <div class="wh-slider-rangebar"></div>
                    <div class="wh-slider-knob">
                      <span></span>
                    </div>
                    <div class="wh-slider-knob">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            { this.filterconfig.filters.map((filter, fidx) => filter.type === "checkbox"
                    ? <div class="productfilter" data-type="checkbox">
                        <a class="productfilter-title" data-toggle="collapse" href={`#productfilter-${fidx}`} aria-expanded="false" aria-controls={`productfilter-${fidx}`}>{ filter.title }</a>
                          <div id={`productfilter-${fidx}`} class="filtertype--checkbox collapse show">
                          <ul class="productfilter--optionlist">
                            { filter.vals.map((val, vidx) =>
                                <li>
                                  <input type="checkbox" id={`filter-${fidx}-${vidx}`} value={`filter-${fidx}-${vidx}`} onChange={applyFilter} data-products={val.products.join(" ")}></input>
                                  <label for={`filter-${fidx}-${vidx}`}>{val.value}</label>
                                </li>)
                            }
                          </ul>
                        </div>
                      </div>
                    : <div class="productfilter" data-type="select">
                        <a class="productfilter-title" data-toggle="collapse" href={`#productfilter-${fidx}`} aria-expanded="false" aria-controls={`productfilter-${fidx}`}>{ filter.title }</a>
                          <div id={`productfilter-${fidx}`} class="filtertype--checkbox collapse show">
                            <select id={`filter-${fidx}`} class="productfilter--select" onChange={applyFilter}>
                              <option value="">Geen voorkeur</option>
                              { filter.vals.map((val, vidx) =>
                                  <option value={vidx+1} data-products={val.products.join(" ")}>{val.value}</option>)
                              }
                            </select>
                        </div>
                      </div>)
            }
            </div>
          </div>);

      node.classList.remove("widget-productfilter--hidden");

      this.pricerange = [this.filterconfig.minprice, this.filterconfig.maxprice];
      let slidernode = node.querySelector(".productfilter-price .wh-slider-holder");
      this.sliderobj = new cSlider( slidernode, { "minvalue"   : this.filterconfig.minprice
                                                , "maxvalue"   : this.filterconfig.maxprice
                                                , "startvalues": this.pricerange
                                                });
      node.addEventListener("sliderchange", ev => {
        let newvalues = ev.detail.values;
        this.pricerange[0] = (newvalues[0] > newvalues[1] ? newvalues[1] : newvalues[0]);
        this.pricerange[1] = (newvalues[0] > newvalues[1] ? newvalues[0] : newvalues[1]);

        this._applyFilter();
        this._saveFilter();
      });

      window.addEventListener("resize", ev => {
        if( !this.node.clientWidth )
          this._resetFilter(); //filters are hidden, the reset filters
        else
          this.sliderobj.refresh();
      });

      //refresh slider when opening filter
      $('#collapseFilter').on("show.bs.collapse", () =>
      {
        //this is invoked with display still none, so wait a tick ...
        setTimeout( () => this.sliderobj.refresh(),1);
      });

      this._applyFilter();

      this._setupFilterRetention()
    }
    else
      node.classList.add("widget-productfilter--hidden");

    this.node.addEventListener("click", evt => this._handleActiveFilterRemove(evt));
  }

  _setupFilterRetention()
  {
    //REAPPLY PREVIOUS FILTER
    if(sessionStorage.skwFilter)
    {
      try
      {
        let filterstate = JSON.parse(sessionStorage.skwFilter);
        if(filterstate.href == location.href) //only reapply if going back to the same category page
        {
          //reapply checkboxes
          filterstate.tocheck.map(id => this.node.querySelector(`[id="${id}"]`))
                             .filter(node => !!node)
                             .forEach(node => node.checked = true);
          //reapply slider if any
          if(filterstate.sliderstate && this.sliderobj)
            this.sliderobj.setValues(filterstate.sliderstate);

          this._applyFilter(); //and actually filter!
        }
      }
      catch(e)
      {
        console.error(e); //and ignore!
      }
    }

    //SETUP NEW RETENTION
    this.node.addEventListener("change", () => window.setTimeout(this._saveFilter(),0));
  }

  _saveFilter()
  {
    //record checkboxes
    let tocheck = dompack.qSA(this.node,"input:checked").map(node => node.id);
    //and the slider, if any
    let sliderstate = this.sliderobj ? this.sliderobj.getValues() : null;
    sessionStorage.skwFilter = JSON.stringify({href: location.href, tocheck: tocheck, sliderstate: sliderstate });
  }

  _updateProductPrices(visibleproductnodes)
  {
    if (!visibleproductnodes.length)
      return;

    // Gather active optionvalues
    const optionvalues = [];

    // Selection of selects
    for (let selnode of dompack.qSA(this.node, "select"))
    {
      const opt = selnode.options[selnode.selectedIndex];
      if (opt && opt.dataset.optionvalues)
        optionvalues.push(...opt.dataset.optionvalues.split(" ").map(v => parseInt(v)).filter(v => v));
    }

    // Checked checkboxes
    for (let checkboxnode of dompack.qSA(this.node, `input[type="checkbox"]`))
      if (checkboxnode.checked && checkboxnode.dataset.optionvalues)
        optionvalues.push(...checkboxnode.dataset.optionvalues.split(" ").map(v => parseInt(v)).filter(v => v));

    this.grid.dataset.webshopSelectOptionvalues = optionvalues.join(" ");
    dompack.dispatchCustomEvent(this.grid, "webshop:optionsupdated", { bubbles:true, cancelable:false });

    const allprices = visibleproductnodes.map(node => node.dataset.webshopCurrentprice);

    // Keep the original slider as much as possible, extend if needed
    const orgfilterconfig = JSON.parse(this.grid.dataset.filterconfig);
    const minprice = finmath.min(...allprices.map(v => finmath.roundToMultiple(v, 1, "down")));
    const maxprice = finmath.max(...allprices.map(v => finmath.roundToMultiple(v, 1, "up")));

    this.sliderobj.updateOptions(
        { minvalue: parseInt(finmath.min(orgfilterconfig.minprice, minprice))
        , maxvalue: parseInt(finmath.max(orgfilterconfig.maxprice, maxprice))
        });
  }

  _resetFilter()
  {
    if (this.filterisreset)
      return;

    for( let node of this.node.querySelectorAll("input, select") )
    {
      if( node.nodeName == "SELECT" )
        node.selectedIndex = 0;
      else if( node.type == "checkbox" || node.type == "radio" )
        node.checked = false;
    }

    this._applyFilter();

    this.pricerange[0] = this.filterconfig.minprice;
    this.pricerange[1] = this.filterconfig.maxprice;
    this.sliderobj.setValues(this.pricerange);

    this._applyFilter();

    this.filterisreset = true;
  }

  _applyFilter()
  {
    this.filterisreset = false;

    let productnodes = Array.from(this.grid.querySelectorAll(".productsgrid__product"));
    let visibleproductnodes = productnodes;
    let filtercloseblocks = []; //blocks that allow us to unselect filters

    let productsgridanchor = document.getElementById("productsgrid_anchor");

filterloop:
    for (let filter of Array.from(this.node.querySelectorAll(".productfilter")).reverse()) // do price last
    {
      let productids = [];
      switch (filter.dataset.type)
      {
        case "price":
        {
          this._updateProductPrices(visibleproductnodes);

          let minprice = this.pricerange[0];
          let maxprice = this.pricerange[1];

          productids = visibleproductnodes.filter(node =>
          {
            const price = node.dataset.webshopCurrentprice;
            return finmath.cmp(price, minprice) >= 0 && finmath.cmp(price, maxprice) <= 0;
          }).map(node => node.dataset.productid);
        } break;
        case "checkbox":
        {
          let checkboxes = Array.from(filter.querySelectorAll("input")).filter(i => i.checked);
          if (!checkboxes.length)
            continue filterloop;
          for (let cb of checkboxes)
          {
            productids = productids.concat(cb.dataset.products.split(" "));
            filtercloseblocks.push(
              <div data-filter-tokill={cb.id} class="productfilter__activelabel">{cb.nextSibling.textContent} <span class="productfilter__activeremove"></span></div>);
          }
        } break;
        case "select":
        {
          let selnode = filter.querySelector("select");
          let opt = selnode.options[selnode.selectedIndex];
          if (!opt.value)
            continue filterloop;
          productids = opt.dataset.products.split(" ");
          console.log('opt', opt);
          filtercloseblocks.push(
            <div data-filter-tokill={selnode.id} class="productfilter__activelabel">{opt.textContent} <span class="productfilter__activeremove"></span></div>);
        } break;
      }

      visibleproductnodes = visibleproductnodes.filter(node => productids.includes(node.dataset.productid));

      //console.log('productids', productids);
      dompack.empty(this.activefilterholder);
      if(filtercloseblocks.length)
        dompack.append(this.activefilterholder,<div class="productfilter__activetitle">U heeft geselecteerd:</div>,...filtercloseblocks);
    }

    productnodes.forEach(node =>
    {
      let visible = visibleproductnodes.includes(node);
      dompack.toggleClass(node, "productsgrid__product--hidden", !visible);
    });

  }

  _handleActiveFilterRemove(evt)
  {
    let target = dompack.closest(evt.target, '.productfilter__activelabel');
    if(!target)
      return;

    dompack.stop(evt);

    let targetfilter = document.getElementById(target.dataset.filterTokill);
    if(targetfilter && targetfilter.nodeName=='INPUT') //assuming checkbox
      dompack.changeValue(targetfilter, false);
    else if(targetfilter && targetfilter.nodeName=='SELECT')
      dompack.changeValue(targetfilter, '');

  }
}

dompack.register(".widget-map__gmap", node => new cMaps(node) );
dompack.register(".widget-productfilter", node => new ProductFilter(node) );
